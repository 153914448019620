module.exports={
    "id": "https://raw.githubusercontent.com/epoberezkin/ajv/master/lib/refs/json-schema-v5.json#",
    "$schema": "http://json-schema.org/draft-04/schema#",
    "description": "Core schema meta-schema (v5 proposals)",
    "definitions": {
        "schemaArray": {
            "type": "array",
            "minItems": 1,
            "items": { "$ref": "#" }
        },
        "positiveInteger": {
            "type": "integer",
            "minimum": 0
        },
        "positiveIntegerDefault0": {
            "allOf": [ { "$ref": "#/definitions/positiveInteger" }, { "default": 0 } ]
        },
        "simpleTypes": {
            "enum": [ "array", "boolean", "integer", "null", "number", "object", "string" ]
        },
        "stringArray": {
            "type": "array",
            "items": { "type": "string" },
            "minItems": 1,
            "uniqueItems": true
        },
        "$data": {
            "type": "object",
            "required": [ "$data" ],
            "properties": {
                "$data": {
                    "type": "string",
                    "anyOf": [
                        { "format": "relative-json-pointer" }, 
                        { "format": "json-pointer" }
                    ]
                }
            },
            "additionalProperties": false
        }
    },
    "type": "object",
    "properties": {
        "id": {
            "type": "string",
            "format": "uri"
        },
        "$schema": {
            "type": "string",
            "format": "uri"
        },
        "title": {
            "type": "string"
        },
        "description": {
            "type": "string"
        },
        "default": {},
        "multipleOf": {
            "anyOf": [
                {
                    "type": "number",
                    "minimum": 0,
                    "exclusiveMinimum": true
                },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "maximum": {
            "anyOf": [
                { "type": "number" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "exclusiveMaximum": {
            "anyOf": [
                {
                    "type": "boolean",
                    "default": false
                },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "minimum": {
            "anyOf": [
                { "type": "number" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "exclusiveMinimum": {
            "anyOf": [
                {
                    "type": "boolean",
                    "default": false
                },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "maxLength": {
            "anyOf": [
                { "$ref": "#/definitions/positiveInteger" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "minLength": {
            "anyOf": [
                { "$ref": "#/definitions/positiveIntegerDefault0" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "pattern": {
            "anyOf": [
                {
                    "type": "string",
                    "format": "regex"
                },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "additionalItems": {
            "anyOf": [
                { "type": "boolean" },
                { "$ref": "#" },
                { "$ref": "#/definitions/$data" }
            ],
            "default": {}
        },
        "items": {
            "anyOf": [
                { "$ref": "#" },
                { "$ref": "#/definitions/schemaArray" }
            ],
            "default": {}
        },
        "maxItems": {
            "anyOf": [
                { "$ref": "#/definitions/positiveInteger" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "minItems": {
            "anyOf": [
                { "$ref": "#/definitions/positiveIntegerDefault0" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "uniqueItems": {
            "anyOf": [
                {
                    "type": "boolean",
                    "default": false
                },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "maxProperties": {
            "anyOf": [
                { "$ref": "#/definitions/positiveInteger" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "minProperties": {
            "anyOf": [
                { "$ref": "#/definitions/positiveIntegerDefault0" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "required": {
            "anyOf": [
                { "$ref": "#/definitions/stringArray" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "additionalProperties": {
            "anyOf": [
                { "type": "boolean" },
                { "$ref": "#" },
                { "$ref": "#/definitions/$data" }
            ],
            "default": {}
        },
        "definitions": {
            "type": "object",
            "additionalProperties": { "$ref": "#" },
            "default": {}
        },
        "properties": {
            "type": "object",
            "additionalProperties": { "$ref": "#" },
            "default": {}
        },
        "patternProperties": {
            "type": "object",
            "additionalProperties": { "$ref": "#" },
            "default": {}
        },
        "dependencies": {
            "type": "object",
            "additionalProperties": {
                "anyOf": [
                    { "$ref": "#" },
                    { "$ref": "#/definitions/stringArray" }
                ]
            }
        },
        "enum": {
            "anyOf": [
                {
                    "type": "array",
                    "minItems": 1,
                    "uniqueItems": true
                },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "type": {
            "anyOf": [
                { "$ref": "#/definitions/simpleTypes" },
                {
                    "type": "array",
                    "items": { "$ref": "#/definitions/simpleTypes" },
                    "minItems": 1,
                    "uniqueItems": true
                }
            ]
        },
        "allOf": { "$ref": "#/definitions/schemaArray" },
        "anyOf": { "$ref": "#/definitions/schemaArray" },
        "oneOf": { "$ref": "#/definitions/schemaArray" },
        "not": { "$ref": "#" },
        "format": {
            "anyOf": [
                { "type": "string" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "formatMaximum": {
            "anyOf": [
                { "type": "string" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "formatMinimum": {
            "anyOf": [
                { "type": "string" },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "formatExclusiveMaximum": {
            "anyOf": [
                {
                    "type": "boolean",
                    "default": false
                },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "formatExclusiveMinimum": {
            "anyOf": [
                {
                    "type": "boolean",
                    "default": false
                },
                { "$ref": "#/definitions/$data" }
            ]
        },
        "constant": {
            "anyOf": [
                {},
                { "$ref": "#/definitions/$data" }
            ]
        },
        "contains": { "$ref": "#" },
        "patternGroups": {
            "type": "object",
            "additionalProperties": {
                "type": "object",
                "required": [ "schema" ],
                "properties": {
                    "maximum": {
                        "anyOf": [
                            { "$ref": "#/definitions/positiveInteger" },
                            { "$ref": "#/definitions/$data" }
                        ]
                    },
                    "minimum": {
                        "anyOf": [
                            { "$ref": "#/definitions/positiveIntegerDefault0" },
                            { "$ref": "#/definitions/$data" }
                        ]
                    },
                    "schema": { "$ref": "#" }
                },
                "additionalProperties": false
            },
            "default": {}
        },
        "switch": {
            "type": "array",
            "items": {
                "required": [ "then" ],
                "properties": {
                    "if": { "$ref": "#" },
                    "then": {
                        "anyOf": [
                            { "type": "boolean" },
                            { "$ref": "#" }
                        ]
                    },
                    "continue": { "type": "boolean" }
                },
                "additionalProperties": false,
                "dependencies": {
                    "continue": [ "if" ]
                }
            }
        }
    },
    "dependencies": {
        "exclusiveMaximum": [ "maximum" ],
        "exclusiveMinimum": [ "minimum" ],
        "formatMaximum": [ "format" ],
        "formatMinimum": [ "format" ],
        "formatExclusiveMaximum": [ "formatMaximum" ],
        "formatExclusiveMinimum": [ "formatMinimum" ]
    },
    "default": {}
}
